<template>
	<div class="my-account loaded">
		<b-container class="content intro" fluid>
			<b-row>
				<b-col>
					<h1>Forgot Password</h1>
					<h4>If you haven't set up an email, you will not be able to reset your password. Please call PKF at 1800 to retrieve your password. You will need to provide your Report ID</h4>
				</b-col>
			</b-row>
		</b-container>
		<b-container class="content body" fluid>
			<b-row>
				<b-col>
					<transition name="fade" mode="out-in">
						<b-form v-if="!submitted" class="mb-4" @submit.prevent="submit">
							<b-form-group
								label="Enter Report ID"
								label-for="reportID"
							>
								<b-form-input id="reportID" ref="reportID" v-model="reportID"></b-form-input>
							</b-form-group>
							<b-form-group
								label="Enter email address"
								label-for="email"
							>
								<b-form-input id="email" ref="email" v-model="email" type="email"></b-form-input>
							</b-form-group>
							<div class="d-flex form-controls">
								<b-button variant="outline-primary" class="mr-auto" @click="cancel">Cancel</b-button>
								<b-button :disabled="disabled" type="submit">Reset</b-button>
							</div>
						</b-form>
						<div v-else>
							<h4>Thank you.</h4>
							<p>We have emailed you a link to reset your password. If you do not receive the email within 15 minutes, please <a href @click.prevent="submitted = false">try again</a>.</p>
						</div>
					</transition>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>

export default {
	name: 'ForgotPassword',
	data() {
		return {
			email: '',
			reportID: '',
			submitted: false
		}
	},
	computed: {
		disabled() {
			return !this.email.length
		}
	},
	mounted() {
		if (this.$store.state.disclosureId) {
			this.reportID = this.$store.state.disclosureId
			this.$refs.email.focus()
		} else {
			this.$refs.reportID.focus()
		}
	},
	methods: {
		submit() {
			const url = `${this.$store.getters.apiPath}${this.reportID}/forgot-password`
			this.$http.post(url, {discloserEmail: this.email})
			this.submitted = true
		},
		cancel() {
			this.$router.back()
		}
	},
	metaInfo: {
		title: 'Forgot Password',
	}
}
</script>
